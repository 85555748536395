<template>
  <div class="page">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/old-mortgage-not-sold-realty" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="promo promo_blocks">{{ "s2p12_text_2" | localize }}</p>

            <div class="form__group">
              <v-text-field
                v-model="price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.price)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('price', price)"
                :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                :hint="
                  ($v.price.$dirty && !$v.price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'c_s1p1_placeholder' | localize"
                append-icon="icon-₪"
                ref="price"
                outlined
              ></v-text-field>
            </div>

            <p class="question">{{ "s2p12_text_1" | localize }}</p>
            <div class="form__group">
              <v-autocomplete
                v-model="bank"
                :items="banks"
                @input="checkForm($v.bank)"
                :class="{ invalid: $v.bank.$dirty && !$v.bank.required }"
                item-text="name"
                item-value="value"
                cache-items
                :placeholder="'s3p7_placeholder_1' | localize"
                append-icon="icon-arrow-down"
                outlined
              ></v-autocomplete>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage-old-mortgage-not-sold-price',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    price: mortgage.old_mortgage_not_sold || null,
    bank: mortgage.old_mortgage_bank || null,
    error: null
  }),
  validations: {
    price: { required },
    bank: { required }
  },
  created () {
    this.price = mortgage.old_mortgage_not_sold ? this.formatNumber(mortgage.old_mortgage_not_sold) : null
  },
  computed: { loaded_banks () { return this.$store.state.banks} },
  watch: { loaded_banks () { this.setBanks() } },
  methods: {
    submitHandler () {
      this.price = this.parseNumber(this.price)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      mortgage.old_mortgage_not_sold = this.price
      mortgage.old_mortgage_bank = this.bank

      this.$cookies.set('mortgage', mortgage)
      this.$router.push('/mortgage/self-money')
    }
  }
}
</script>
